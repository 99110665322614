import React from "react";

function ShowcasePage() {
  return (
    <div className="showcase-page">
      <div>
        <h1 className="tagline">
          Discover Hidden Gems in the World of Indie Gaming
        </h1>
        <p className="description">
          Explore a hand-picked selection of indie games from across the globe
          with DevIndie. Dive into unique worlds crafted with passion and
          creativity, and find your next favorite game with ease. Whether you're
          after captivating stories, innovative gameplay, or breathtaking art,
          our curated collections bring the best of indie gaming right to your
          fingertips. Don't just play games—discover experiences that inspire
          and excite.
        </p>
      </div>
    </div>
  );
}

export default ShowcasePage;
